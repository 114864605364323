import React, { useContext, useState } from 'react';
import { Card, CloseButton, Col, Modal, Row } from 'react-bootstrap';
//import { useNavigate, useLocation } from 'react-router';
//import RegistrationForm from 'components/authentication/RegistrationForm';
import LoginForm from "./LoginForm"
import RegistrationForm from "./RegistrationForm"
import ForgetPasswordForm from "./ForgetPasswordForm"
//import AppContext from 'context/Context';

//import AuthenticationContext from 'utilities/Authentication';
import Section from 'components/common/Section';
import Logo from 'components/common/Logo';




export default function LogInPage() {
    //let {
    //    state: { open }
    //  } = useLocation();
    // const navigate = useNavigate();
    //const authContext = useContext(AuthContext);
    //let location = useLocation();
    //const [show, setShow] = useState(false);

    const [currentTab,setCurrentTab] = useState("LogIn") //authContext.modalTab

    const handleClose = () => {
        setCurrentTab("LogIn");
       // authContext.changeAuthState({ modalTab:"LogIn" }); //"showModal": false })
    };



    console.log("at auth modal");
    console.log(currentTab);

    let modalHeader = ""

    if (currentTab == "LogIn") {
        modalHeader = "Log In";
    }
    else if (currentTab == "Register") {
        modalHeader = "Register";
    }
    else if (currentTab == "ForgotPassword") {
        modalHeader = "Forgot Password";
    }
    else if (currentTab == "ResetPassword") {
        modalHeader = "Reset Password";
    }
    else if (currentTab == "ConfirmEmail") {
        modalHeader = "Confirm Email";
    }

    return (

        <Section className="py-0">
            <Row style={{alignItems:"start !important"}} className="flex-center min-vh-100 py-6">
                <Col sm={10} md={8} lg={6} xl={5} className="col-xxl-4">
                    <Logo fontSize="30px" />
                    <Card>
                        <div className="bg-shape modal-shape-header px-4 position-relative card-header">
                            <div className="position-relative z-index-1 light">
                                <h4 className="mb-0 text-white" id="authentication-modal-label">{modalHeader}</h4>
                            </div>
                            <CloseButton
                                variant="white"
                                className="position-absolute end-0 me-2 mt-2 top-0"
                                onClick={handleClose}
                            />
                        </div>
                        <Card.Body className="p-4 p-sm-5">
                            <div className="p-4">
                                {(() => {
                                    if (currentTab == "LogIn") {
                                        return (
                                            <LoginForm currentTab={currentTab} setCurrentTab={setCurrentTab} hasLabel={false} />
                                        )
                                    }
                                    else if (currentTab == "Register") {
                                        return (
                                            <RegistrationForm currentTab={currentTab} setCurrentTab={setCurrentTab} hasLabel={false} />
                                        )
                                    }
                                    else if (currentTab == "ForgotPassword") {
                                        return (
                                            <ForgetPasswordForm currentTab={currentTab} setCurrentTab={setCurrentTab} hasLabel={true} />
                                        )
                                    }
                                    else if (currentTab == "ResetPassword") {
                                        modalHeader = "Reset Password";
                                    }
                                    else if (currentTab == "ConfirmEmail") {
                                        modalHeader = "Confirm Email";
                                    }
                                })()}


                                {/*<LoginForm hasLabel={false} changeTab={changeTab} />
       <div id="firebaseui-container"> </div>
        <RegistrationForm layout="split" hasLabel />*/}
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Section>



    );


};


